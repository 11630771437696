<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">打卡信息</a-divider>
			<a-descriptions :column="1">
				<a-descriptions-item label="员工姓名">
					{{ detail.username }}
				</a-descriptions-item>
				<a-descriptions-item label="员工部门">
					{{ detail.department_name ||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="出发地点">
					{{ detail.start_place }}
				</a-descriptions-item>
				<a-descriptions-item label="出发打卡">
					{{ detail.start||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="打卡时间">
					{{ detail.clock_start_time||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="打卡地点">
					{{ detail.clock_start_place ||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="打卡拍照" >
                    <LookImages v-if="detail.clock_start_image"  :list="[detail.clock_start_image]"></LookImages>
                    <!-- <img :src="detail.clock_start_image"  v-if="detail.clock_start_image"  class="clock_img" /> -->
                    <span v-else >--</span>
				</a-descriptions-item>
				<a-descriptions-item label="到达地点">
					{{ detail.end_place||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="到达打卡">
					{{ detail.end ||'--'}}
				</a-descriptions-item>
                <a-descriptions-item label="打卡时间">
                	{{ detail.clock_end_time||'--' }}
                </a-descriptions-item>
                <a-descriptions-item label="打卡地点">
                	{{ detail.clock_end_place ||'--'}}
                </a-descriptions-item>
                <a-descriptions-item label="打卡拍照">
                    <LookImages v-if="detail.clock_end_image"  :list="[detail.clock_end_image]"></LookImages>
                    <!-- <img :src="detail.clock_end_image"  class="clock_img" v-if="detail.clock_start_image"/> -->
                    <span v-else >--</span>
                </a-descriptions-item>
			</a-descriptions>
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>

		</div>
	</div>
	</div>
</template>

<script>
	import LookImages from "@/components/LookImages";
	import {
		clockInfo,placeClockDetail
	} from '@/api/evection.js'
	import Avatar from "@/components/small/Avatar";
	export default {
		components: {
			LookImages,
			Avatar
		},
		data() {
			return {
				detail: {
					trip: [],
					check_in: [],
					order:{}
				}
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			get_info() {
				placeClockDetail({
					data: {
						id: this.$route.query.id,
					}

				}).then(res => {
					this.detail = res.data.list;
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.time_line {
		max-width: 600px;
	}
	.avatar_ul {
		display: flex;
		flex-wrap: wrap;

		.avatar {
			margin: 16px 10px 0;
			width: 80px;
		}
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.li_item {
		.title_item {
			width: 80px;
			text-align: left;
			display: inline-block;
			color: #000;
		}

		margin-bottom: 30px;
	}

	.arpproval_title {
		height: 48px;
		padding: 15px 15px;
		text-align: left;
		background: rgba(245, 245, 245, 1);

		span {
			width: 9%;
			display: inline-block;
		}

		.department_appr {
			width: 25%;
		}
	}

	.arpproval_title1 {
		height: auto;
		background: none;
		border: 1px solid rgba(245, 245, 245, 1);
	}
	.go_see_title {
			text-align: center;
	}
	.go_see {
		color: #1890ff;
		text-align: center;
	}
    .clock_img {
        width: 100px;
    }
</style>
